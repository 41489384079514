export const fr = {
  loading: 'Chargement ...',
  MyChaban: 'Mon Pont Chaban',
  opened: 'Ouvert',
  closeIn: 'Ferme dans',
  reopenIn: 'Réouvre dans',
  month: [
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
  ],
  weekDays: ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'],
};
